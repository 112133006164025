'use client';

import { AppPalettes, DesignSystemProvider } from '@prismo-io/design-system';
import '@prismo-io/design-system/module-augmentation';
import { env } from '@prismo-io/env';
import { SessionListener } from '@prismo-io/feature-auth/session-listener';
import { AppType, type LanguageCodeT } from '@prismo-io/schemas';
import { NpsSurveyProvider } from '@prismo-io/tracking';
import { UsermavenProvider } from '@prismo-io/tracking';
import type { FC, ReactNode } from 'react';
type Props = {
  children: ReactNode;
  sessionAccessToken?: string;
  locale: LanguageCodeT;
};
export const GlobalProviders: FC<Props> = props => {
  const {
    children,
    locale,
    sessionAccessToken
  } = props;
  return <UsermavenProvider apikey={env.NEXT_PUBLIC_USERMAVEN_KEY} withPageTracking data-sentry-element="UsermavenProvider" data-sentry-component="GlobalProviders" data-sentry-source-file="providers.tsx">
      <>
        <SessionListener data-sentry-element="SessionListener" data-sentry-source-file="providers.tsx" />
        <DesignSystemProvider theme={AppPalettes.light} options={{
        key: 'mui'
      }} data-sentry-element="DesignSystemProvider" data-sentry-source-file="providers.tsx">
          <NpsSurveyProvider app={AppType.Enum.QUARK} data-sentry-element="NpsSurveyProvider" data-sentry-source-file="providers.tsx">
            {children}
          </NpsSurveyProvider>
        </DesignSystemProvider>
      </>
    </UsermavenProvider>;
};